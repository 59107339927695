<template>
  <div>
    <el-card>
      <div slot="header">
        <span>提现审核</span>
      </div>

      <el-form :inline="true" label-width="120px" size="mini">
        <el-form-item>
          <el-button type="primary" @click="queryBasic()" v-permission="'priceAudit:queryUserApply'">刷新</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="beforeCommit()" v-permission="'priceAudit:updateUserApply'">审核</el-button>
        </el-form-item>
        <el-form-item>
        </el-form-item>
      </el-form>
      <div>
        <el-table
          :max-height="500"
          :height="500"
          :data="tableData"
          border
          ref="multipleTable"
          size="medium"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column type="index" label="序号" fixed="left"/>
          <el-table-column prop="money" label="提现金额"></el-table-column>
          <el-table-column prop="withdrawOrder" show-overflow-tooltip label="提现订单号"></el-table-column>
          <el-table-column prop="actualMoney" label="实际到账金额"></el-table-column>
          <el-table-column prop="balance" label="手续费"></el-table-column>
          <el-table-column prop="rate" label="本次提现利率 单位%" width="150"></el-table-column>
          <el-table-column prop="applyTime" show-overflow-tooltip label="提交时间" width="150"></el-table-column>
          <el-table-column prop="siteName" label="院校" width="150"></el-table-column>
          <el-table-column prop="phoneNum" show-overflow-tooltip label="联系电话" width="150"></el-table-column>
          <el-table-column prop="applyName" label="申请人姓名" width="150"></el-table-column>
          <el-table-column prop="applyAcc" show-overflow-tooltip label="提现支付宝账号" width="150"></el-table-column>
          <el-table-column prop="withdrwInfo" show-overflow-tooltip label="审核备注" width="150"></el-table-column>
          <el-table-column prop="status" label="状态" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.status===-1">未通过</span>
              <span v-if="scope.row.status===0">审核中</span>
              <span v-if="scope.row.status===1">已提现</span>
              <span v-if="scope.row.status===2">提现失败</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryBO.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryBO.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="审核"
      :visible.sync="applyVisible"
      width="30%">
      <div>
        <el-form label-width="80px">
          <el-form-item label="审核意见:">
            <el-select v-model="commitBO.status" placeholder="请选择审核意见">
              <el-option
                v-for="item in [{value:1,label:'审核通过'},{value:-1,label:'审核被拒'}]"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="applyVisible = false">取 消</el-button>
        <el-button type="primary" @click="commit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "price-audit",
    data() {
      return {
        queryBO: {
          page: 1,
          pageSize: 10
        },
        tableData: [],
        total: 0,
        commitBO: {
          status: 1,
        },
        applyVisible: false,
        chooseList: [],
        multipleSelection: []
      }
    },
    methods: {
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      commit() {
        let formData = new FormData();
        let ids = this.getIds(this.multipleSelection, 'id');
        formData.append('ids', ids);
        formData.append('status', this.commitBO.status);
        this.openLoadingView();
        this.$axios_supermallData.post('hnjxjy-core/eduStreamWithdrw/updateUserApply', formData).then(response => {
          if (response.data.code === 2000) {
            this.commitBO = {
              status: 1,
            };
            this.multipleSelection = [];
            this.$message.success("审核完成");
            this.applyVisible = false;
            this.queryBasic();
          }
        })
      },
      beforeCommit() {
        if (this.multipleSelection == null || this.multipleSelection.length <= 0) {
          this.$message.warning("至少选择一项");
          return;
        }
        this.applyVisible = true;
      },
      queryBasic() {
        this.getPermission('priceAudit:queryUserApply',()=>{
          let data = '?page=' + this.queryBO.page + "&pageSize=" + this.queryBO.pageSize;
          this.openLoadingView();
          this.$axios_supermallData.get('hnjxjy-core/eduStreamWithdrw/queryUserApply' + data).then(response => {
            if (response.data.code === 2000) {
              this.tableData = response.data.data.list;
              this.total = response.data.data.total;
            }
          })
          this.loadingView.close();
        })
      },
      handleSizeChange(val) {
        this.queryBO.pageSize = val;
        this.queryBasic()
      },
      handleCurrentChange(val) {
        this.queryBO.page = val;
        this.queryBasic()
      },
    },
    mounted() {
      this.queryBasic();
    }
  }
</script>

<style scoped>

</style>
